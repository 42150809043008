<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col-reverse lg:flex-row lg:gap-x-12 gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div class='flex flex-col justify-between items-center lg:items-end'>
        <div>
          <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/logo_square.png'
             class='h-24 cursor-pointer mb-6 w-auto mt-2'
             @click='goToHome'/>
        </div>
        <div
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer w-full'
          @click='logoutApp'>
          logout
        </div>
      </div>
      <div class='text-sm flex-grow'>
        <div class='mt-2 flex flex-col lg:flex-row justify-start text-left w-full items-start font-bold text-lg'>
          Local Congress Secretariat of APSR 2022
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-24'>Address</span> 
           <span class='block text-left w-full lg:w-auto'>513, Yeongdong-daero, Gangnam-gu, Seoul 06164, Korea</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-24'>Email</span> 
          <span class='block text-left w-full lg:w-auto'>info@apsr2022.org</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-24'>Fax</span> 
          <span class='block text-left w-full lg:w-auto'>82-2-6944-8306</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start text-left w-full items-start'>
          Copyright © APSR2022 all rights reserved
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Apsr2022LayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.replace({name: 'Login'}).catch(() => {})
      })
    },
    goToHome () {
      this.$router.push({name: 'Welcome'}).catch(() => {})
    },
  },
  created () {
    this.checkTokenStatus()
  }
}
</script>
